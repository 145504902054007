<template>
  <container-list-extend title="Prod" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <base-icon class="ml-2 mr-2" name="calendar" height="35" width="35" color="#0069d9"></base-icon>
        <h5 class="m-0 ml-1" v-if="prod.mois">
          {{
          $moment(prod.mois)
          .locale("fr")
          .format("MMMM YYYY")
          }}
        </h5>
        <h6 class="m-0 ml-1" v-else>Nouvelle Prod</h6>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <div v-if="prod.montant">
            Montant produit :
            {{ prod.montant.toLocaleString() }} €
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        deleteBtn
        @click="updateProd()"
        @delete="deleteProd()"
        :loading="loading"
        :statut="statut"
      >
        <base-form-group title="Mouvenement en production" dense icon="sliders">
          <base-form-row row>
            <base-inputDatePicker
              v-model="prod.mois"
              inputText="Mois"
              :errors="feedback.mois"
              :minimumView="'month'"
              :maximumView="'month'"
            ></base-inputDatePicker>
            <base-input-number
              v-model="prod.montant"
              inputText="Montant produit"
              :errors="feedback.montant"
            ></base-input-number>
          </base-form-row>
          <base-form-row row>
            <base-input-area
              v-model="prod.commentaire"
              text="Commentaire"
              :errors="feedback.commentaire"
            ></base-input-area>
          </base-form-row>
          <!-- 
          <div class="mt-3 mb-3">
            <div
              class="text-primary cursor-pointer"
              v-show="!displayCalcul"
              @click="displayCalcul= !displayCalcul"
            >
              <small>Affichier le calcul de production</small>
            </div>
            <div
              class="text-primary cursor-pointer"
              v-show="displayCalcul"
              @click="displayCalcul= !displayCalcul"
            >
              <small>Masquer le calcul de production</small>
            </div>
          </div>

          <div v-show="displayCalcul">
            <div v-for="chiffrage in chiffrages" :key="chiffrage.id">
              <prod-item-calcul
                :chiffrage="chiffrage"
                v-if="chiffrage.production_id === prod.id"
                @updateProdChiffrage="updateProdChiffrage"
              ></prod-item-calcul>
            </div>
          </div>-->
        </base-form-group>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
//import BaseInput from "@/components/bases/Input.vue";
//import BaseSelect from "@/components/bases/Select.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
//import ProdItemCalcul from "@/components/affaires/ProdItemCalcul.vue";
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  name: "FormationsItem",
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputNumber,
    BaseInputArea,
    BaseInputDatePicker,
    //ProdItemCalcul,
    BaseIcon,
    BaseFormGroup
    //    BaseSelect,
    //    BaseInput
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: "",
      displayCalcul: false
    };
  },
  props: {
    prod: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    lists: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  computed: {
    ...mapMultiRowFields("productions", ["chiffrages"]),
    ...mapGetters({
      data: "affaires/data"
    }),
    totalChiffrages() {
      let somme = 0;
      for (let index = 0; index < this.chiffrages.length; index++) {
        const chiffrage = this.chiffrages[index];
        if (chiffrage.production_id === this.prod.id) {
          somme = somme + parseFloat(chiffrage.production);
        }
      }
      return somme;
    }
  },
  methods: {
    ...mapActions({
      _updateProd: "productions/update",
      _deleteProd: "productions/delete"
    }),
    updateProd: function() {
      this.feedback = {};
      this.loading = true;
      this._updateProd(this.prod)
        .then(response => {
          this.statut = response.data;
          this.$emit("updateProd");
        })
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    updateProdChiffrage: function() {
      this.prod.montant = this.totalChiffrages;
      this.updateProd();
    },
    deleteProd: function() {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteProd(this.prod)
        .then(() => this.$emit("deleteProd"))
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css"></style>
