<template>
  <div class="d-flex flex-column flex-grow-1 rounded mr-md-3">
    <BaseToolBar title="Production" subtitle="Liste des mouvements en production">      
      <BaseButton
      class="btn btn-primary ml-3"
      v-tooltip="'Ajouter une ligne de production'"
      @click="createProd(id)"
      :loading="loading"
      icon="plus"
      text="Ajouter"
    ></BaseButton>
    </BaseToolBar>
    <div class="recap_affaire mb-3 p-2 rounded shadow-sm" :style="{ backgroundColor: bg }">
      <div class="d-flex flex-column flex-md-row align-items-center">
        <h3 class="m-0 ml-md-3">Production</h3>
        <div class="ml-md-auto rounded p-md-3">
          <h3
            class="m-0"
            v-if="affaire_commande_montant >0"
          >{{ affaire_commande_montant.toLocaleString() }} €</h3>
          <h3 class="m-0" v-else>0 €</h3>
          <div>
            <small>Total commandé</small>
          </div>
        </div>
        <div class="ml-md-2 rounded p-md-3" v-if="avancement">
          <h3 class="m-0">{{ montantProduit.toLocaleString() }} €</h3>
          <div>
            <small>Total produit</small>
          </div>
        </div>
        <div class="ml-md-2 rounded p-md-3" v-if="avancement">
          <h3 class="m-0">{{ parseFloat(avancement).toFixed() }} %</h3>
          <div>
            <small>Avancement</small>
          </div>
        </div>
      </div>
      <div class="progress ml-3 mr-3 mt-2 mb-2" style="height: 4px;">
        <div
          class="progress-bar"
          role="progressbar"
          v-bind:style="{ width: parseInt(avancement) + '%' }"
        ></div>
      </div>
      <small>
        <div class="months-bar d-flex mb-2 p-2 justify-content-start flex-wrap">
          <div
            v-for="month in months"
            :key="month.month"
            class="text-center p-2 m-1"
            style="width:100px;"
          >
            <div>
              {{
              $moment(month.month)
              .locale("fr")
              .format("MMM YYYY")
              }}
            </div>
            <div
              :class="{
                'text-muted': month.montant === 0,
                'text-success': month.montant > 0
              }"
            >{{ month.montant }} €</div>
          </div>
        </div>
      </small>
    </div>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column flex-md-row flex-wrap mb-3" v-if="productions">
        <div v-for="prod in productions" :key="prod.id" class="w-100">
          <prod-item
            :prod="prod"
            :lists="lists"
            @updateProd="CalculRecap"
            @deleteProd="CalculRecap"
          ></prod-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import ProdItem from "@/components/affaires/ProdItem.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: { BaseToolBar, ProdItem, BaseButton },
  data() {
    return {
      lists: {},
      feedback: {},
      months: [],
      loading:false
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      totalProduit: "productions/totalProduit"
    }),
    ...mapFields("affaires", [
      "affaire.id",
      "affaire.affaire_production",
      "affaire.affaire_commande_montant",
      "affaire.affaire_date_debut",
      "affaire.affaire_date_fin"
    ]),
    ...mapMultiRowFields("productions", ["productions"]),
    bg() {
      return this.$store.getters["colors/colors"].color3;
    },
    avancement() {
      if (this.affaire_commande_montant > 0) {
        let calc = (this.montantProduit / this.affaire_commande_montant) * 100;
        return calc.toLocaleString();
      }
      return 0;
    },
    montantProduit() {
      let somme = 0;
      for (let index = 0; index < this.productions.length; index++) {
        const prod = this.productions[index];
        if (prod.montant > 0) {
          somme = somme + parseFloat(prod.montant);
        }
      }
      return somme;
    }
  },
  methods: {
    ...mapActions({
      _createProd: "productions/create"
    }),
    createProd: function() {
      this.feedback = {};
      this.loading = true
      this._createProd({ affaire_id: this.id })
        .catch(error => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
    errHandler: function(error) {
      console.log(error);
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    CalculRecap: function() {
      if (this.productions && this.productions.length > 0) {
        let dateStart = this.$moment(this.affaire_date_debut);
        let dateEnd = this.$moment(this.affaire_date_fin);
        let timeValues = [];
        let _montant = 0;

        while (
          dateEnd > dateStart ||
          dateStart.format("M") === dateEnd.format("M")
        ) {
          _montant = 0;
          for (let index = 0; index < this.productions.length; index++) {
            const prod = this.productions[index];
            if (
              this.$moment(prod.mois).format("YYYY-MM") ===
              dateStart.format("YYYY-MM")
            ) {
              if (prod.montant) {
                _montant = _montant + parseFloat(prod.montant);
              }
            }
          }
          if (_montant === 0) {
            timeValues.push({ month: dateStart.format("YYYY-MM"), montant: 0 });
          } else {
            timeValues.push({
              month: dateStart.format("YYYY-MM"),
              montant: parseFloat(_montant)
            });
          }
          dateStart.add(1, "month");
        }
        this.months = timeValues;
      }
    }
  },
  mounted() {
    //this.CalculRecap();
  }
};
</script>
